<template>
  <!-- Media uploader label linked to file input -->
  <label
    class="media-uploader"
    for="media-files-input"
    @dragenter="dropzoneVisible = true"
    @dragover.prevent
  >
    <!-- Content -->
    <div class="media-uploader__content">
      <!-- Error alert (errorsBag) -->
      <v-alert
        v-for="error in errorsBag"
        :key="error.title"
        type="error"
        outlined
        dense
      >
        {{ error.title }}
      </v-alert>
      <!-- Inscrutions copy + upload button -->
      <h2
        v-if="!!$vuetify.breakpoint.mdAndUp"
      >
        {{ $t('mediamanager.drop_files') }}
      </h2>
      <p
        v-if="!!$vuetify.breakpoint.mdAndUp"
      >
        {{ $t('mediamanager.or') }}
      </p>
      <v-btn
        color="primary"
      >
        {{ $t('mediamanager.select_files') }}
      </v-btn>
      <!-- Hidden file input linked to top label click action -->
      <input
        id="media-files-input"
        ref="files"
        :accept="fileTypes.join()"
        type="file"
        multiple
        @change="handleFilesInputChange"
      >
      <!-- Conditions -->
      <p class="mb-0">
        {{ $t('mediamanager.accepted_format') }} {{ acceptedFormats }}.
      </p>
      <p class="mb-0">
        {{ $t('mediamanager.size_limit') }} {{ formatWeight(sizeLimit) }}.
      </p>
    </div>
    <!-- Dropzone: A zone that appears if the user is dragging a file over it and serves as an entry point for dataTransfer -->
    <transition name="fade">
      <div
        v-if="dropzoneVisible === true"
        class="media-uploader__dropzone"
        @drop.prevent="handleFilesDrop"
        @dragleave="dropzoneVisible = false"
      />
    </transition>
  </label>
</template>

<script>
import mime from 'mime-types';
import mediaManagerMixin from '../../mixins/mediaManager';

export default {
  mixins: [
    mediaManagerMixin
  ],
  props: {
    typeFilter: {
      type: Array,
      default: null,
    }
  },
  data: () => ({
    files: [],
    filesValid: false,
    errorsBag: [],
    dropzoneVisible: false,
  }),
  computed: {
    formats () {
      // Filter extensions based on `typeFilter` prop
      return this.getMediaFormats(this.typeFilter);
    },
    fileTypes () {
      return this.formats
        .map(format => mime.lookup(format))
        .filter(type => type);
    },
    acceptedFormats () {
      return this.formats
        .map(format => `.${format}`)
        .join(', ');
    },
    sizeLimit () {
      return this.config.sizeLimit;
    },
  },
  methods: {
    /**
     * Event registered when the user drops a file over the dropzone
     *
     * @param {Object} e - Drop event
     * @returns {Void}
     */
    handleFilesDrop (e) {
      this.files = e.dataTransfer.files;
      this.dropzoneVisible = false;
      this.filesValidationGuard();
    },
    /**
     * Event registered when the user clicks on the label and select a file from the native modal
     *
     * @returns {Void}
     */
    handleFilesInputChange () {
      this.files = this.$refs.files.files;
      this.filesValidationGuard();
    },
    /**
     * Front-End validation to prevent the user from uploading files that are too large
     * or are not in one of the supported formats
     *
     * @returns {Void}
     */
    filesValidationGuard () {
      // Reset errorsBag
      this.errorsBag = [];
      this.files.forEach(file => {
        // Check if file is in a supported format
        if (this.fileTypes.includes(file.type) === false) {
          this.errorsBag.push({
            title: this.$t('errors.wrong_format', {
              fileName: file.name,
              acceptedFormats: this.acceptedFormats,
            })
          });
        // Check if file is under size limit
        } else if (file.size > this.sizeLimit) {
          this.errorsBag.push({
            title: this.$t('errors.file_too_large', {
              fileName: file.name,
              fileSize: this.formatWeight(file.size),
              sizeLimit: this.formatWeight(this.sizeLimit),
            })
          });
        }
      });
      // If all files are valid, start the upload
      if (this.errorsBag.length === 0) {
        this.uploadFiles();
      }
    },
    /**
     * If files are all valid creates a FormData object & send to parent media manager
     * for file(s) treatment
     *
     * @returns {Void}
     */
    uploadFiles () {
      this.$emit('files-upload', this.files);
      this.files = [];
    },
  }
};
</script>

<style lang="scss" scoped>
// General
.media-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  @include rem(min-height, 368px);

  padding: $spacer;
  border: 3px dashed var(--color-border);
  cursor: pointer;
}

// Content
.media-uploader__content {
  text-align: center;

  ::v-deep .v-btn {
    pointer-events: none;
  }

  input {
    display: none;
  }

  > * {
    margin-bottom: $spacer;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Dropzone
.media-uploader__dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);

  ::v-deep .v-icon {
    @include rem(font-size, 100px);

    position: absolute;
  }
}
</style>
