<template>
  <v-navigation-drawer
    :value="id != null"
    v-bind="$attrs"
    width="300"
    absolute
    right
    @input="handleDrawerInput"
  >
    <base-spinner
      v-if="!media"
      class="ma-4"
    />
    <template v-else>
      <!-- Attachment details -->
      <div class="media-picker__details">
        <div class="d-flex justify-space-between align-center mb-4">
          <h4
            v-t="'mediamanager.attachment_details'"
            class="mb-0"
          />
          <v-icon
            @click="close"
            v-text="'close'"
          />
        </div>
        <p
          v-for="field in detailsFields"
          :key="field.key"
        >
          <strong v-if="field.key === config.itemTitle">
            {{ media[field.key] }}
          </strong>
          <template v-else-if="field.key === 'weight'">
            {{ formatWeight(media[field.key]) }}
          </template>
          <template v-else-if="field.type === 'date'">
            {{ formatDate(media[field.key]) }}
          </template>
          <template v-else>
            {{ media[field.key] }}
          </template>
        </p>
        <div class="mt-2">
          <!-- Save button -->
          <v-btn
            :loading="saveLoading"
            :disabled="saveDisabled"
            class="mr-2"
            color="primary"
            small
            @click.native="handleSaveClick"
          >
            {{ $t('ui.save') }}
          </v-btn>
          <!-- Delete button -->
          <v-btn
            class="pa-0"
            color="error"
            small
            text
            @click="handleDeleteClick"
          >
            {{ $t('mediamanager.delete') }}
          </v-btn>
          <!-- Copy button -->
          <v-btn
            :color="isCopied ? 'secondary': 'primary'"
            class="mt-1 mr-2"
            small
            @click.native="copyTextToClipboard"
          >
            {{ isCopied ? $t('ui.link_copied') : $t('ui.copy_link') }}
            <span
              v-if="isCopied"
              class="material-icons media-picker-copy-icon"
            >
              done
            </span>
          </v-btn>
        </div>
      </div>
      <v-divider />
      <!-- Form -->
      <v-form
        ref="form"
        v-model="formValid"
        class="media-picker__form"
      >
        <v-alert
          v-if="showContentLangWarning === true"
          type="warning"
          border="left"
          class="mb-3"
          outlined
          dense
        >
          {{ $t('warnings.content_lang') }}
        </v-alert>
        <!-- Info item -->
        <type-fields
          :fields="editFields"
          :data="workingData"
          :grid="false"
          :vuetify-props="{ hideDetails: false }"
          @update:data="handleUpdateData"
        />
      </v-form>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import gql from 'graphql-tag';
import moment from 'moment';
import { cloneDeep, get } from 'lodash';

import mediaManagerMixin from  '../../mixins/mediaManager';

export default {
  name: 'MediaInfo',
  mixins: [
    mediaManagerMixin
  ],
  inheritAttrs: false,
  props: {
    // Defined by MediaPicker
    id: {
      type: Number,
      default: null,
    },
  },
  apollo: {
    media: {
      query () {
        this.media = null;
        this.isCopied = false;

        return gql`
          query {
            media: ${this.config.idSingular}(id: ${this.id}) {
              ${this.queryFields.map(field => field.related || field.key)}
            }
          }
        `;
      },
      skip () {
        return this.id == null;
      },
      result ({ data, error, loading }) {
        if (loading || error) {
          return;
        }

        this.workingData = Object.assign(cloneDeep(data.media), this.formData);
      }
    }
  },
  data () {
    return {
      media: null,
      workingData: {},
      formData: {},
      formValid: false,
      saveLoading: false,
      editFields: [],
      isCopied: false,
    };
  },
  computed: {
    ...mapState('content', ['contentLang']),
    fetchFields () {
      return this.config.fields.filter(field => (
        field.fetch && (
          field.fetch.details ||
          field.fetch.edit
        )
      ));
    },
    detailsFields () {
      return this.config.fields.filter(field => (
        field.display &&
        field.display.details
      ));
    },
    queryFields () {
      return this.fetchFields.concat(this.detailsFields, this.editFields);
    },
    hasRequiredFields () {
      return this.editFields.some(field => field.required === true);
    },
    saveDisabled () {
      if (this.saveLoading) {
        return true;
      }

      return !this.formValid || !Object.values(this.formData).length;
    },
    editing () {
      return Object.keys(this.formData).length > 0;
    },
    locales () {
      const { itemLocales } = this.config;

      if (this.media && typeof this.media[itemLocales] === 'string') {
        return this.media[itemLocales].split(',');
      }

      return [];
    },
    showContentLangWarning () {
      return this.locales.length > 0 && this.locales.includes(this.contentLang) === false;
    }
  },
  watch: {
    id (id, oldId) {
      if (id !== oldId) {
        this.formData = {};
      }
    },
    media (newMedia, oldMedia) {
      if (oldMedia === null || (oldMedia !== null && newMedia !== null && oldMedia.id !== newMedia.id)) {
        this.editFields = this.config.fields.filter(field => {
          if (typeof get(field, 'display.edit') === 'function') {
            return field.display.edit(newMedia);
          }
          return field.display && field.display.edit;
        });
      }
    }
  },
  mounted () {
    this.unsubscribeFromMutations = this.$store.subscribe(mutation => {
      if (mutation.type === 'content/startUpdateContentLang') {
        this.formData = {};
      }
    });
  },
  destroyed () {
    this.unsubscribeFromMutations();
  },
  methods: {
    close () {
      this.$emit('update:id', null);
    },
    formatDate (value) {
      moment.locale(this.$i18n.locale);
      return moment(value).format('ll');
    },
    handleDrawerInput (value) {
      if (!value) {
        this.close();
      }
    },
    handleUpdateData ({ key, value }) {
      this.$set(this.workingData, key, value);
      this.$set(this.formData, key, value);
    },
    async handleSaveClick () {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saveLoading = true;

      try {
        await this.$apollo.mutate({
          mutation: this.createPatchMediaQuery({ id: this.id }),
          variables: {
            patch: this.formData
          }
        });
      } catch (error) {
        console.error(error);
        this.$reportError({ message: `${error.message}` });
        return;
      } finally {
        this.saveLoading = false;
      }

      this.formData = {};

      this.$store.$apollo.defaultClient.resetStore();

      this.$reportSuccess({ message: this.$t('dialogs.update_success') });
    },
    handleDeleteClick () {
      this.$emit('delete');
    },
    /*
     * Main method for copying media link to clipboard
     *
     * @returns {void}
     */
    async copyTextToClipboard () {
      const text = this.media.file;
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(() => {
        this.isCopied = true;
        console.log('Async: Copying to clipboard was successful!', text);
      }, (err) => {
        console.error('Async: Could not copy text: ', err);
      });
    },
    /*
     * Fallback method (older browsers) for copying to clipboard
     *
     * @returns {void}
     */
    async fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        if (successful) {
          this.isCopied = true;
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
  }
};
</script>

<style lang="scss" scoped>
// Top details
.media-picker__details {
  background-color: var(--color-background);
  padding: $spacer;

  h4 {
    margin-bottom: $spacer;
    text-transform: uppercase;
    line-height: 1;
  }

  p {
    margin-bottom: 0;
  }
}

// Form
.media-picker__form {
  padding: $spacer $spacer 0;
}

.media-picker-copy-icon {
  @include rem(font-size, 16px);
  @include rem(padding-left, 3px);
}
</style>
