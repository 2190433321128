<template>
  <div class="media-picker">
    <!-- Left content (filters, grid) -->
    <div :class="['media-picker__left', { 'drawer-is-active': selectedId != null }]">
      <!-- Filters -->
      <div class="media-picker__filters">
        <!-- Sort by -->
        <div>
          <label
            v-t="'mediamanager.sort_by'"
            for="filter-sort"
            class="label-input"
          />
          <v-btn
            icon
            x-small
            class="ml-1"
            @click="reverseOrder"
          >
            <v-icon
              :class="['media-picker__order-icon', { 'is-descending': order !== 'ASC' }]"
              small
            >
              arrow_upward
            </v-icon>
          </v-btn>
          <v-select
            id="filter-sort"
            :value="sort"
            :items="sortByItems"
            :placeholder="$t('generic.select_value')"
            class="mr-4"
            hide-details
            outlined
            dense
            clearable
            @change="handleSortChange"
          />
        </div>
        <!-- Type filter -->
        <div
          v-if="typeItems.length > 0"
        >
          <label
            v-t="'mediamanager.type'"
            for="filter-type"
            class="label-input"
          />
          <v-autocomplete
            id="filter-type"
            :value="typeValue"
            :items="typeItems"
            :placeholder="$t('generic.select_value')"
            class="mr-4"
            hide-details
            outlined
            dense
            clearable
            multiple
            @change="handleTypeChange"
          />
        </div>
        <!-- Categories filter -->
        <div
          v-if="config.filterCategories"
        >
          <label
            v-t="'category.categories'"
            for="filter-category"
            class="label-input"
          />
          <v-autocomplete
            id="filter-category"
            :value="type"
            :items="filterCategories"
            :placeholder="$t('generic.select_value')"
            class="mr-4"
            hide-details
            outlined
            dense
            clearable
            multiple
            @change="handleCategoriesChange"
          />
        </div>
        <v-spacer />
        <!-- Search -->
        <div>
          <label
            v-t="'mediamanager.search'"
            for="filter-search"
            class="label-input"
          />
          <v-text-field
            id="filter-search"
            :value="search"
            hide-details
            outlined
            dense
            @input="handleSearchInput"
          >
            <template #append>
              <v-chip
                v-if="count !== null && search !== ''"
                :close="search !== ''"
                class="mr-1"
                close-icon="close"
                color="primary"
                small
                @click:close="clearSearch"
              >
                {{ count }}
              </v-chip>
              <v-icon>search</v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <!-- No files disclaimer -->
      <p
        v-if="hasItems === false"
        v-t="search !== '' ? 'mediamanager.no_search_results' : 'mediamanager.no_files'"
        class="flex-grow-1"
      />
      <!-- Grid -->
      <div
        v-else
        class="media-picker__grid"
      >
        <media-picker-item
          v-for="(media, i) in loadingItems"
          :key="'loading' + i"
          :loading="true"
          :title="media[config.itemTitle]"
        />
        <!-- Item -->
        <media-picker-item
          v-for="media in computedItems"
          :key="media.id"
          :loading="media.id < 0"
          :title="media.title"
          :url="media.file"
          :type="media.type"
          :selected="media.id === selectedId"
          :show-checkbox="multiple"
          :checked="value"
          :value="media.id"
          @update:checked="$emit('input', $event)"
          @click="handleItemClick(media)"
        />
      </div>
      <div class="media-picker__pagination">
        <div class="media-picker__pagination-pages">
          <v-pagination
            v-if="pageCount > 1"
            :key="pageCount"
            :value="page"
            :total-visible="5"
            :length="pageCount"
            :disabled="loadingItems.length > 0"
            @input="handlePaginationInput"
          />
        </div>
        <div
          v-if="items.length > 0"
          class="media-picker__pagination-items"
        >
          <label
            class="media-picker__items-per-page-label mr-3"
            for="media-items-per-page"
          >
            {{ $t('mediamanager.items_per_page') }}
          </label>
          <v-select
            id="media-items-per-page"
            :value="itemsPerPage"
            :items="[10, 25, 50, 75]"
            dense
            hide-details
            @change="handleItemsPerPageChange"
          />
        </div>
      </div>
    </div>
    <!-- Right navigation drawer with infos and form -->
    <media-info
      :id="selectedId"
      ref="mediaInfo"
      @update:id="updateSelectedId($event)"
      @delete="deleteModalVisible = true"
    />
    <!-- Delete item dialog -->
    <custom-dialog
      v-model="deleteModalVisible"
      :title="$t('mediamanager.modales.delete_modal_title')"
      :subtitle="$t('mediamanager.modales.delete_modal_subtitle')"
      :show-action="true"
      @confirm="handleDeleteConfirm"
    />
    <!-- Editing guard dialog -->
    <custom-dialog
      v-model="editingModalVisible"
      :title="$t('dialogs.exit_without_saving_title')"
      :subtitle="$t('dialogs.exit_without_saving_subtitle')"
      :show-action="true"
      @confirm="handleEditingConfirm"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';

import CustomDialog from '../Modales/CustomDialog';
import MediaPickerItem from './MediaPickerItem';
import MediaInfo from './MediaInfo';
import mediaManagerMixin from '../../mixins/mediaManager';

export default {
  name: 'MediaPicker',
  components: {
    CustomDialog,
    MediaPickerItem,
    MediaInfo,
  },
  mixins: [
    mediaManagerMixin
  ],
  props: {
    value: {
      type: [Number, Array],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: null,
    },
    loadingItems: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: null,
    },
    order: {
      type: String,
      default: 'DESC'
    },
    search: {
      type: String,
      default: '',
    },
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    filterCategories: {
      query () {
        return gql`
          query mediacategories {
            filterCategories: mediacategories (
              queryInput: {
                options: "no_backup_lang"
              }
            ) {
              value: id
              text: title
            },
          }
        `;
      },
      skip () {
        // Skip in case we don't have this filter activated
        return !this.config.filterCategories === true;
      }
    },
  },
  data () {
    return {
      deleteModalVisible: false,
      editingModalVisible: false,
      targetSelectedId: null,
      selectedId: null,
      typeValue: null,
    };
  },
  computed: {
    computedItems () {
      let items = this.items;
      if (this.loadingItems.length > 0) {
        items.splice(this.itemsPerPage - this.loadingItems.length, this.loadingItems.length);
      }
      return items;
    },
    editing () {
      return this.$refs.mediaInfo.editing;
    },
    sortByItems () {
      return this.config.fields
        .filter(field => field.sortable)
        .map(field => ({
          text: this.$t(`mediamanager.filters.${field.label}`),
          value: field.sortClause || field.key
        }));
    },
    pageCount () {
      return Math.ceil((this.count + this.loadingItems.length) / this.itemsPerPage);
    },
    hasItems () {
      return this.items.length > 0 || this.loadingItems.length > 0;
    },
    typeItems () {
      return this.config.filterTypeItems !== undefined
        ? this.config.filterTypeItems.map(type => {
          return {
            text: this.$t(type.text),
            value: type.value,
          };
        })
        : [];
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (this.multiple === false) {
          this.selectedId = value;
        }
      },
    },
  },
  created () {
    this.typeValue = this.type;
  },
  methods: {
    showEditingModal (id) {
      this.targetSelectedId = id;
      this.editingModalVisible = true;
    },
    clearSearch () {
      this.$emit('update:search', '');
      this.$emit('update:page', 1);
    },
    reverseOrder () {
      this.$emit('update:order', this.order === 'ASC' ? 'DESC' : 'ASC');
    },
    updateSelectedId (value) {
      if (this.multiple === false) {
        this.$emit('input', value);
      } else {
        this.selectedId = value;
      }
    },
    handleSortChange (value) {
      this.$emit('update:sort', value);
      this.$emit('update:order', 'DESC'); // Reset order
    },
    handleTypeChange (value) {
      this.$emit('update:typeFilter', value);
    },
    handleCategoriesChange (value) {
      this.$emit('update:categoriesFilter', value);
    },
    handleItemsPerPageChange (value) {
      this.$emit('update:items-per-page', value);
      this.$emit('update:page', 1);
    },
    handleSearchInput (value) {
      this.$emit('update:search', value);
      this.$emit('update:page', 1);
    },
    handleEditingConfirm () {
      this.updateSelectedId(this.targetSelectedId);
    },
    handleItemClick (item) {
      if (this.editing) {
        this.showEditingModal(item.id);
      } else {
        this.updateSelectedId(item.id);
      }
    },
    async handleDeleteConfirm () {
      this.deleteModalVisible = false;

      try {
        await this.$apollo.mutate({
          mutation: this.createDeleteMediaQuery({ id: this.selectedId })
        });
      } catch (error) {
        console.error(error);
        this.$reportError({ message: `${error.message}` });
        return;
      }

      this.updateSelectedId(null);

      this.$store.$apollo.defaultClient.resetStore();

      this.$reportSuccess({ message: this.$t('dialogs.remove_success') });
    },
    handlePaginationInput (page) {
      this.$emit('update:page', page);
      this.updateSelectedId(null);
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.media-picker {
  position: relative;

  @include rem(height, 500px);

  overflow-x: hidden;
}

// Left
.media-picker__left {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 $spacer 0;

  @media (min-width: $bp-lg) {
    &.drawer-is-active {
      @include rem(margin-right, 250px);
    }
  }
}

// Filters
.media-picker__filters {
  display: flex;
  padding: $spacer/5 0 $spacer;

  .v-input {
    @include rem(max-width, 200px);
  }
}

.media-picker__order-icon {
  transition: transform 0.2s ease;

  &.is-descending {
    transform: rotate(180deg);
  }
}

// Grid
.media-picker__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: $spacer;
  flex: 1 1 auto;
  overflow: auto;
}

.media-picker__pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacer/2 0;

  @media (min-width: $bp-sm) {
    flex-direction: row;
    justify-content: space-between;

    @include rem(min-height, 60px);
  }

  &-items {
    align-items: center;
    display: flex;
    flex: 0 0 auto;

    ::v-deep .v-select {
      margin-top: 0;

      @include rem(max-width, 46px);

      &__selection--comma {
        font-size: 0.75rem;
      }
    }
  }
}

.media-picker__items-per-page-label {
  font-size: 0.75rem;
  white-space: nowrap;
  margin-left: auto;
}
</style>
