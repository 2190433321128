<template>
  <v-dialog
    :value="visible"
    :scrollable="true"
    v-bind="$attrs"
    max-width="320"
    @input="$emit('update:visible', $event)"
    @keydown.enter="confirm"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title class="body-1 elevation-1 mb-3 font-weight-bold py-3">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ subtitle }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="close"
        >
          {{ $t('ui.close') }}
        </v-btn>
        <v-btn
          v-if="showAction"
          color="primary"
          @click="confirm"
        >
          {{ $t('ui.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  inheritAttrs: false,
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      required: true,
      default: true,
    },
    title: {
      type: String,
      required: true,
      default: 'Please specify a title prop',
    },
    subtitle: {
      type: String,
      required: true,
      default: 'Please specify a subtitle prop',
    },
  },
  methods: {
    close ($event) {
      this.$emit('update:visible', false);
    },
    confirm ($event) {
      this.$emit('confirm');
      this.close();
    },
  }
};
</script>
