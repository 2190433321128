<template>
  <div
    :class="[
      'media-picker__item',
      { 'media-picker__item--uploading': loading },
      { 'media-picker__item--selected': selected },
    ]"
    v-on="$listeners"
  >
    <base-spinner v-if="loading" />
    <template v-else>
      <!-- Item cover image -->
      <img
        v-if="fileType === 'image'"
        :src="url"
        :alt="title"
        :title="title"
      >
      <!-- Item cover video -->
      <video v-else-if="fileType === 'video'">
        <source
          :src="url"
          type="video/mp4"
        >
      </video>
      <!-- Other item placeholder -->
      <span
        v-else
        class="media-picker__placeholder"
        v-text="fileExtension"
      />
      <!-- Item type chip (with changing icon) -->
      <v-icon
        class="media-picker__icon"
        v-html="fileIcon"
      />
      <!-- Actions -->
      <div class="media-picker__actions">
        <!-- Checkbox -->
        <v-checkbox
          v-if="showCheckbox"
          :input-value="checked"
          :value="value"
          :ripple="false"
          color="primary"
          hide-details
          multiple
          class="media-picker__checkbox"
          @change="$emit('update:checked', $event)"
          @click.stop
        />
        <!-- Open preview overlay -->
        <v-btn
          icon
          color="primary"
          width="24px"
          height="24px"
          @click.stop="handleOpenOverlay"
        >
          <v-icon>search</v-icon>
        </v-btn>
      </div>
      <!-- Media overlay -->
      <media-overlay
        v-if="overlay"
        v-model="overlay"
        :name="title"
        :file="url"
        :type="fileType"
      />
    </template>
  </div>
</template>

<script>
import mediaManagerMixin from  '../../mixins/mediaManager';

export default {
  name: 'MediaPickerItem',
  mixins: [
    mediaManagerMixin,
  ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: [Array, Number], // Number included to avoid errors when checkboxes are disabled
      default: null,
    },
    value: {
      type: Number,
      default: null,
    }
  },
  data () {
    return {
      overlay: false,
    };
  },
  computed: {
    fileExtension () {
      return this.url.substring(this.url.lastIndexOf('.'));
    },
    fileType () {
      let fileType = 'file';
      if (this.type.includes('image/')) {
        fileType = 'image';
      } else if (this.type.includes('video/')) {
        fileType = 'video';
      }
      return fileType;
    },
    fileIcon () {
      let fileIcon = 'insert_drive_file';
      if (this.fileType === 'image') {
        fileIcon = 'image';
      } else if (this.fileType === 'video') {
        fileIcon = 'videocam';
      }
      return fileIcon;
    },
  },
  methods: {
    handleOpenOverlay () {
      if (['image', 'video'].includes(this.fileType)) {
        this.overlay = true;
      } else {
        window.open(this.url, '_blank');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// Item
.media-picker__item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  position: relative;
  padding-top: 100%;
  overflow: hidden;

  &:not(.media-picker__item--uploading) {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  img,
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    max-width: 100%;
    max-height: 100%;

    @include rem(padding, 5px);

    transform: translate(-50%, -50%);
  }

  ::v-deep {
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// Placeholder
.media-picker__placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: $spacer * 2;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

// File icon
.media-picker__icon {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: white;
  border-radius: 4px;
}

// Actions
.media-picker__actions {
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
}

// Checkbox
.media-picker__checkbox {
  padding: 0;
  margin: 0;

  ::v-deep {
    * {
      margin: 0;
    }

    i {
      color: var(--color-primary);
    }
  }
}

// Modifiers
.media-picker__item--selected,
.media-picker__item--uploading {
  box-shadow: inset 0 0 0 2px var(--color-primary), inset 0 0 0 4px white;
}

.media-picker__item--uploading {
  cursor: not-allowed;
}
</style>
