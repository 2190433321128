import gql from 'graphql-tag';

export default {
  computed: {
    config () {
      return this.$xms.config.modules.mediaManager;
    },
  },
  methods: {
    createPatchMediaQuery ({ id }) {
      const capitalizedSingularId = this.config.idSingular.slice(0, 1).toUpperCase()
        + this.config.idSingular.slice(1);
      
      const mutationName = `patch${capitalizedSingularId}`;
      const patchType = `${capitalizedSingularId}Patch`;

      return gql`
        mutation ${mutationName}($patch: ${patchType}!) {
          ${mutationName}(
            id: ${id}
            patch: $patch
          ) {
            id
          }
        }
      `;
    },
    createDeleteMediaQuery ({ id }) {
      const capitalizedSingularId = this.config.idSingular.slice(0, 1).toUpperCase()
        + this.config.idSingular.slice(1);
      
      const mutationName = `delete${capitalizedSingularId}`;

      return gql`
        mutation ${mutationName} {
          ${mutationName}(
            id: ${id}
          )
        }
      `;
    },
  }
};
