<template>
  <v-card>
    <!-- Tabs -->
    <v-tabs v-model="tab">
      <!-- Upload tab -->
      <v-tab :key="$t('mediamanager.upload')">
        <v-icon>cloud_upload</v-icon>
        <!-- Hide labels on mobile only -->
        {{ !!$vuetify.breakpoint.smAndUp ?`&nbsp;&nbsp;${$t('mediamanager.upload')}` : null }}
      </v-tab>
      <!-- Library tab -->
      <v-tab :key="$t('mediamanager.library')">
        <!-- Current files uploading notification badge -->
        <v-badge
          :value="!!loadingMedias.length"
          :content="loadingMedias.length"
          offset-x="13"
          offset-y="13"
          color="error"
          bordered
          left
        >
          <v-icon>photo_album</v-icon>
          <!-- Hide labels on mobile only -->
          <span
            class="mt-2 d-inline-block"
            v-html="!!$vuetify.breakpoint.smAndUp ? `&nbsp;&nbsp;${$t('mediamanager.library')}` : null"
          />
        </v-badge>
      </v-tab>
    </v-tabs>
    <!-- Content (Tabs items) -->
    <v-card-text
      class="media-manager-content pa-0"
    >
      <v-tabs-items
        v-model="tab"
        touchless
      >
        <!-- Media upload tab item -->
        <v-tab-item
          :reverse-transition="false"
          :transition="false"
          class="pa-4"
        >
          <!-- Media uploader component -->
          <media-uploader
            :type-filter="typeFilter"
            @files-upload="handleFilesUpload"
          />
        </v-tab-item>
        <!-- Media picker tab item -->
        <v-tab-item
          :reverse-transition="false"
          :transition="false"
        >
          <!-- Media picker component -->
          <media-picker
            ref="mediaPicker"
            :value="value"
            :items="medias"
            :count="mediaCount"
            :loading-items="loadingMedias"
            :type-filter.sync="typeValue"
            :categories-filter.sync="categoriesFilter"
            :sort.sync="sort"
            :order.sync="order"
            :search.sync="search"
            :page="page"
            :items-per-page.sync="itemsPerPage"
            :multiple="multiple"
            @input="handleMediaPickerInput"
            @update:page="$emit('update:page', $event)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <template v-if="$slots && $slots.actions">
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import gql from 'graphql-tag';
import mime from 'mime-types';

import MediaUploader from './MediaUploader';
import MediaPicker from './MediaPicker';
import mediaManagerMixin from '../../mixins/mediaManager';

export default {
  components: {
    MediaUploader,
    MediaPicker,
  },
  mixins: [
    mediaManagerMixin,
  ],
  props: {
    value: {
      type: [Number, Array],
      default: null,
    },
    page: {
      type: Number,
      default: 1,
    },
    typeFilter: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: [Number, Array],
      default: () => ([]),
    },
  },
  apollo: {
    medias: {
      query () {
        return gql`
          query medias (
            $queryInput: QueryInput
          ) {
            medias: ${this.config.id} (
              queryInput: $queryInput
            ) {
              ${this.queryFields.map(field => field.key)}
            },
            nmedias: n${this.config.id} (
              queryInput: $queryInput
            ) {
              count
            }
          }
        `;
      },
      variables () {
        return {
          queryInput: {
            offset: this.itemsPerPage * (this.page - 1),
            limit: this.itemsPerPage,
            sort: this.sort,
            order: this.order,
            fields: this.search
              ? this.searchFields.map(field => field.key).join(',')
              : null,
            search: this.search || null,
            filter: this.filterValues,
          }
        };
      },
      result ({ data, error, loading }) {
        if (error || loading) {
          return;
        }

        this.mediaCount = data.nmedias.count;
      },
    },
  },
  data: () => ({
    medias: [],
    mediaCount: 0,
    loadingMedias: [],
    sort: null,
    order: 'DESC',
    search: '',
    itemsPerPage: 10,
    typeValue: null,
    categoriesFilter: null,
    tab: 1,
  }),
  computed: {
    filterValues () {
      const filters = [];
      if (this.typeValue != null && this.typeValue.length > 0) {
        filters.push(`type[like]:${this.typeValue.join('(||)')}`);
      }
      if (this.categoriesFilter != null && this.categoriesFilter.length > 0) {
        filters.push(`mediacategory_id:${this.categoriesFilter.join('(||)')}`);
      }
      return filters.join(',');
    },
    queryFields () {
      return this.config.fields.filter(field => (
        field.fetch &&
        field.fetch.listing
      ));
    },
    searchFields () {
      return this.config.fields.filter(field => field.search);
    },
    // Navigation guard to prevent the user from accidentally quitting the module while files are uploading
    uploading () {
      return this.loadingMedias.length > 0;
    },
    editing () {
      return this.$refs.mediaPicker.editing;
    },
  },
  watch: {
    uploading (value) {
      this.$emit('uploading', value);
    },
    // Update loading prop when ever loading medias length changes
    loadingMedias (loadingMedias) {
      const newLoading = loadingMedias.length > 0;

      if (newLoading !== this.loading) {
        this.$emit('update:loading', newLoading);
      }
    }
  },
  created () {
    this.typeValue = this.typeFilter;
  },
  methods: {
    handleMediaPickerInput (input) {
      // Put last element of array in first position if
      // an element is added in the selection
      if (input !== null && Array.isArray(input) && input.length > this.selectedIds.length) {
        input.unshift(input.pop());
      }

      this.$emit('input', input);
    },
    /**
     * Add medias.
     * Makes a mutation for each file.
     *
     * @param {FileList} files - The file list
     */
    addMedias (files) {
      return Promise.all(Array.prototype.map.call(files, async file => {
        // Prepare item
        const item = await this.getMediaData(file);

        // Handle loading
        const loadingMedia = {
          ...item,
          id: -1,
          file: '',
        };

        this.loadingMedias.push(loadingMedia);

        // Execute mutation
        let success;

        try {
          success = await this.$apollo.mutate({
            mutation: this.createAddMediaQuery(),
            variables: {
              item
            },
          });
        } catch (error) {
          // Return error after failure
          return error;
        }

        return success;
      }));
    },
    /**
     * Handle files upload event
     *
     * @param {FileList} files - The file list
     */
    async handleFilesUpload (files) {
      this.tab = 1;
      this.$emit('update:page', 1);

      const results = await this.addMedias(files);

      // @jeanmichelax2: To prevent request in flight bug, we reset the store
      // only when all the items have been uploaded. We also clear the loading
      // medias at this moment. This is a temporary solution. Later todo: we
      // need to figure out a way to have a "per file" update, with a better
      // async handling. Maybe a linear loading instead of circular.
      this.loadingMedias = [];
      this.$store.$apollo.defaultClient.resetStore();

      // Error
      if (results.some(result => result instanceof Error)) {
        this.$reportError({ message: this.$t('mediamanager.report.upload_fail') });

        // TODO: Add warning about partial upload
        // results.filter(resulte => result instanceof Error) // Medias not uploaded
        // results.filter(resulte => !(result instanceof Error)) // Medias uploaded

      // Success
      } else {
        this.$reportSuccess({ message: this.$t('mediamanager.report.upload_success') });
      }

      this.pendingModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.media-manager-content {
  position: relative;
  border-top: 1px solid var(--color-border);

  @include rem(margin-top, -1px);
}

// Tabs overrides
::v-deep .v-tabs > .v-tabs-bar {
  background-color: transparent;
}
</style>
